import { Skeleton } from '@nextui-org/skeleton';

import Tooltip from '@atoms/new/tooltip';
import { type AutocompleteItem } from '.';
import { getDefaultTopics } from '@services/generative-ai';
import { useQuery } from '@tanstack/react-query';
import Button from '@atoms/new/button';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import ShuffleRoundedIcon from '@mui/icons-material/ShuffleRounded';
import { useEffect, useState } from 'react';
import { pushDataLayerForEvent } from '@lib/gtag';
interface BucketProps {
  regex: RegExp;
  title: string;
  topics: AutocompleteItem[];
}
interface TryOutClassNamesProps {
  base: string;
  items: {
    base: string;
    text: string;
  };
}
interface TryOutSectionProps {
  compact?: boolean;
  isWebsite?: boolean;
  onClick?: (item: AutocompleteItem) => void;
  tryOutHeaderTitle?: string;
  classNames?: TryOutClassNamesProps;
  initialTopics?: AutocompleteItem[];
  buckets?: BucketProps[];
  showSingleItem?: boolean;
  toolTipContent?: string;
  loadingState?: boolean | undefined | null;
}

const defaultClassNames = {
  base: 'flex items-center flex-col justify-center rounded-md p-3 pt-10',
  items: {
    base: '',
    text: '',
  }
};

export const TryOutSection = ({
  isWebsite = false,
  compact = true,
  onClick = () => { },
  classNames = defaultClassNames,
  tryOutHeaderTitle = 'Try it out',
  initialTopics = [],
  buckets = [],
  showSingleItem = false,
  toolTipContent,
  loadingState,
}: TryOutSectionProps) => {
  const { data, isLoading } = useQuery({
    queryKey: ['getTryOutAiItems'],
    queryFn: () => getDefaultTopics(),
  });

  const [items, setItems] = useState<AutocompleteItem[]>([]);
  const [bucketsData, setBucketsData] = useState<BucketProps[]>(buckets);
  const [disabled, setDisabled] = useState<boolean | undefined | null>(false);

  const groupTopicsByBucket = (data: AutocompleteItem[]) => {
    const tmpBuckets = [...bucketsData];
    tmpBuckets.forEach((bucket) => {
      bucket.topics = [];
      data.forEach((item: AutocompleteItem) => {
        if (item?.tag?.match(bucket.regex)) {
          bucket?.topics?.push(item);
        }
      });
    });
    setBucketsData(tmpBuckets);
    return tmpBuckets;
  };

  const getRandomIndex = (max: number) => Math.floor(Math.random() * max);

  useEffect(() => {
    if (data) {
      // Prepare buckets data if required.
      let tmpBucketsData;
      if (buckets.length > 0) {
        tmpBucketsData = groupTopicsByBucket(data.entity);
      }

      // Set initial topics
      if (initialTopics.length > 0) {
        setItems([initialTopics[getRandomIndex(initialTopics.length)]]);
      } else if (tmpBucketsData) {
        handleShuffleFromBucket(tmpBucketsData);
      } else {
        const subset = selectSubset(data.entity, compact ? 2 : 3);
        setItems(subset);
      }
    }
  }, [data, compact]);

  const handleShuffleFromBucket = (bucketsParam?: BucketProps[]) => {
    const bucketsItems: AutocompleteItem[] = [];
    const buckets = bucketsParam || bucketsData;
    buckets.forEach((bucket) => {
      let unselectedTopics: AutocompleteItem[] = bucket?.topics?.filter((topic) => !topic.isSelected);
      if (unselectedTopics?.length === 0) {
        bucket.topics?.forEach((topic) => {
          topic.isSelected = false;
        });
        unselectedTopics = bucket.topics;
      }
      const randomTopic = unselectedTopics[getRandomIndex(unselectedTopics.length)];
      randomTopic.isSelected = true;
      bucketsItems.push(randomTopic);
    });
    if (showSingleItem) {
      setItems([bucketsItems[getRandomIndex(bucketsItems.length)]]);
    } else {
      setItems(bucketsItems);
    }
  };

  const handleShuffle = () => {
    if (isWebsite) {
      pushDataLayerForEvent('sandbox_shuffle');
    }
    if (bucketsData.length > 0) {
      handleShuffleFromBucket();
    } else {
      const subset = selectSubset(shuffleArray(data.entity), compact ? 2 : 3);
      setItems(subset);
    }
  };

  function shuffleArray(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  function selectSubset(shuffledArray: any[], max: number = 3) {
    const selectedItems = [];
    let otherCategoryCount = 0;

    for (const item of shuffledArray) {
      if (otherCategoryCount < 1 && item.category === 'Other') {
        selectedItems.push(item);
        otherCategoryCount++;
      }
    }

    for (const item of shuffledArray) {
      if (selectedItems.length === max) {
        break;
      }

      if (item.category !== 'Other') {
        selectedItems.push(item);
      }
    }

    return selectedItems.reverse();
  }

  useEffect(() => {
    if (loadingState !== undefined || loadingState !== null) {
      setDisabled(loadingState);
    }
  }, [loadingState]);

  return (
    <div
      className={`${classNames.base} ${compact ? 'p-0' : 'p-6'} ${isWebsite ? 'p-8 rounded-xl' : ''} ${disabled ? ' opacity-50 cursor-not-allowed ' : ''}`}
    >
      <p className={'text-grey-900 dark:text-white text-sm font-semibold text-center'}>
        {tryOutHeaderTitle}
      </p>

      <div className="flex flex-wrap gap-2 py-3 justify-center">
        {isLoading &&
          new Array(compact ? 2 : 3)
            .fill(0)
            .map((_, i) => <Skeleton key={i} className="w-52 h-8 rounded-md" />)}
        {items.map((item: AutocompleteItem, index: number) => (
          <TryOutAiItem
            disabled={!!disabled}
            isWebsite={isWebsite}
            onClick={() => {
              setDisabled(true);
              onClick(item);
            }}
            key={index}
            compact={compact}
            classNames={classNames}
            tooltipContent={toolTipContent}
          >
            {item.topic}
          </TryOutAiItem>
        ))}
      </div>
      <Button
        disabled={!!disabled}
        onClick={handleShuffle}
        size="sm"
        startContent={<ShuffleRoundedIcon fontSize="small" />}
        kind="tertiary"
      >
        Shuffle
      </Button>
    </div>
  );
};

interface TryOutAiItemProps {
  onClick: () => void;
  isWebsite?: boolean;
  children: React.ReactNode;
  compact?: boolean;
  classNames: TryOutClassNamesProps;
  disabled?: boolean;
  tooltipContent?: string;
}

export const TryOutAiItem = (props: TryOutAiItemProps) => {
  const item = (
    <div
      onClick={props.disabled ? () => { } : () => props.onClick()}
      className={`flex bg-white/85 dark:bg-white ${props.disabled ? 'cursor-not-allowed' : 'cursor-pointer'} select-none gap-2 border rounded-md border-grey-100 py-3 px-2 hover:border-primary-500 ${props.classNames?.items.base}`}
    >
      <AutoAwesomeRoundedIcon className="text-grey-900" fontSize="small" />
      <p className={`text-sm text-grey-900 ${props.compact ? 'w-40' : `w-33 ${props.classNames.items.text}`} ${props.isWebsite ? 'w-45' : ''}`}>
        {props.children}
      </p>
    </div>
  );

  if (props.tooltipContent) {
    return (
      <Tooltip
        closeDelay={0}
        content={props.tooltipContent}
        color='default'
        showArrow={true}
        placement='top'
        classNames={{
          base: [
            'before:dark',
          ],
          content: [
            'dark text-neutral-100 rounded-md p-3 shadow -z-10 text-sm',
          ],
        }}
      >
        {item}
      </Tooltip>
    );
  } else {
    return item;
  }
};
